import {
    assistDemoModeAtom,
    assistDomainAtom,
    assistLicensesAtom,
    auditDemoModeAtom,
    easyDemoModeAtom,
    translateDemoModeAtom,
    auditLicensesAtom,
    availableAuditEntriesAtom,
    currentAuditEntryAtom,
    currentAuditEntryIdAtom,
    translateEasyLicenses,
    demoModeAtom,
    fullCdnPathAtom,
    fullUsersCdnPathAtom,
    jiraConfigAtom,
    orgUsersAtom,
    reportDemoModeAtom,
    rerenderLayoutAtom,
    selectedDomainAtom,
    todosDataAtom,
    tokenExpiresAtom,
    userAtom,
    userNotificationsAtom
} from '@/global-store';
import calculateAccessibilityData from '@/helpers/calculateAccessibilityData';
import formatDateToLocale from '@/helpers/formatDateToLocale';
import useDebounce from '@/hooks/useDebounce';
import { inputValues } from '@/pages/assist/Customize/inputValues';
import { defaultReportSettings } from '@helpers/constants';
import { useNotification } from '@hooks/useNotification';
import { api, demoEntry, demoUser, queryKeys, reportStorageUrl, storageUrl } from '@http/constants';
import fetchWrapper from '@http/fetchWrapper';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import clone from 'nanoclone';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import packageJson from '../../package.json';

const version = packageJson.version;

/*
inspired by https://www.bigbinary.com/blog/react-query
*/

const offlineMode = import.meta.env.VITE_OFFLINE_MODE === 'true';
if (offlineMode) console.info('running in offline mode');

// --------------------------------------- Admin Queries ---------------------------------------
export function useFetchUsersAdmin(id = 'all') {
    return useQuery({
        queryKey: [api.users, id],
        queryFn: async () => {
            return id === 'all' ? fetchWrapper.get(api.users) : fetchWrapper.get(api.users, { id });
        }
    });
}

export function useFetchTranslateClientsAdmin() {
    console.log('useFetchTranslateClientsAdmin', api.translateClientsAll);
    return useQuery({
        queryKey: [api.translateClientsAll],
        queryFn: async () => {
            return fetchWrapper.get(api.translateClientsAll);
        }
    });
}

export function useUpdateTranslateClientsAdmin() {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => {
            return fetchWrapper.put(api.translateClients, body);
        },
        onSuccess: data => {
            console.log('useUpdateTranslateClientsAdmin onSuccess', data);

            notification.success('translateClients updated!');

            queryClient.setQueryData([api.translateClientsAll], oldData => {
                const newData = clone(oldData);
                const index = newData.findIndex(client => client.id === data.id);
                newData[index] = data;
                return newData;
            });

            return data;
        },
        onError: error => {
            console.error('useUpdateTranslateClientsAdmin failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function usePostTranslateClientsAdmin() {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => {
            return fetchWrapper.post(api.translateClients, body);
        },
        onSuccess: data => {
            console.log('usePostTranslateClientsAdmin onSuccess', data);

            notification.success('New translateClients created!');

            queryClient.invalidateQueries({
                queryKey: [api.translateClientsAll]
            });

            queryClient.invalidateQueries({
                queryKey: [api.translateClients]
            });

            return data;
        },
        onError: error => {
            console.error('usePostTranslateClientsAdmin failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function usePostOrganisationAdmin() {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => {
            return fetchWrapper.post(api.organisations, body);
        },
        onSuccess: data => {
            console.log('usePostOrganisationAdmin onSuccess', data);

            queryClient.setQueryData([api.organisations, 'all'], oldData => {
                const newData = clone(oldData) || [];
                newData.push(data);
                return newData;
            });

            notification.success('New organisation created!');

            return data;
        },
        onError: error => {
            console.error('usePostOrganisationAdmin failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useUpdateOrganisationAdmin() {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => {
            return fetchWrapper.put(api.organisations, body);
        },
        onSuccess: data => {
            console.log('useUpdateOrganisationAdmin onSuccess', data);

            queryClient.setQueryData([api.organisations, 'all'], oldData => {
                const newData = clone(oldData);
                const index = newData.findIndex(org => org.id === data.id);
                newData[index] = data;
                return newData;
            });

            notification.success('Organisation updated!');

            return data;
        },
        onError: error => {
            console.error('useUpdateOrganisationAdmin failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useCreateAuditLicenseAdmin() {
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => fetchWrapper.post(api.auditLicenses, body)
    });
}

export function useCreateAssistLicenseAdmin() {
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => fetchWrapper.post(api.assistLicenses, body)
    });
}

export function useCreateReportEntryAdmin() {
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => fetchWrapper.post(api.reportEntries, body)
    });
}

export function useUpdateReportEntryAdmin() {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);
    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => fetchWrapper.put(api.reportEntries, body),
        onSuccess: data => {
            console.log('useUpdateReportEntryAdmin onSuccess', data);

            notification.success('Report entry updated!');

            queryClient.invalidateQueries({
                queryKey: [api.reportEntries]
            });

            queryClient.invalidateQueries({
                queryKey: [queryKeys.ADMIN_SEARCH]
            });

            return data;
        },
        onError: error => {
            console.error('useUpdateReportEntryAdmin failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useUpdateReportEntryRescan() {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);
    const [, setAvailableAuditEntries] = useAtom(availableAuditEntriesAtom);

    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        mutationFn: body => fetchWrapper.put(api.reportEntryRescan, body),
        onSuccess: data => {
            console.log('useUpdateReportEntryRescan onSuccess', data);

            notification.success('Success.');

            queryClient.invalidateQueries({
                queryKey: [api.reportEntryRescan]
            });

            setAvailableAuditEntries(oldData => {
                const updatedData = oldData.map(entry => {
                    if (entry.id === data.id) {
                        return {
                            ...entry,
                            ...data
                        };
                    }
                    return entry;
                });

                return updatedData;
            });

            return data;
        },
        onError: error => {
            console.error('useUpdateReportEntryRescan failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useUpdateAuditAssistLicenseAdmin(type) {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const [user] = useAtom(userAtom);

    const url = type === 'assist' ? api.assistLicenses : api.auditLicenses;

    return useMutation({
        enabled: !!(user?.isAdmin || user?.isSalesAdmin || !!type),
        mutationFn: body => fetchWrapper.put(url, body),
        onSuccess: data => {
            console.log('useUpdateAuditAssistLicenseAdmin onSuccess', data);

            notification.success(type + ' license updated!');

            queryClient.invalidateQueries({
                queryKey: [url]
            });

            queryClient.invalidateQueries({
                queryKey: [queryKeys.ADMIN_SEARCH]
            });

            return data;
        },
        onError: error => {
            console.error('useUpdateAuditAssistLicenseAdmin failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useSearchAdmin(endpoint, searchterm = '', delay = 1) {
    const [user] = useAtom(userAtom);
    const debouncedSearchQuery = useDebounce(searchterm, delay);
    return useQuery({
        retry: 0,
        keepPreviousData: true,
        enabled: !!(user?.isAdmin || user?.isSalesAdmin),
        queryKey: [queryKeys.ADMIN_SEARCH, endpoint, debouncedSearchQuery],
        queryFn: () => {
            return new Promise(resolve => {
                if (!debouncedSearchQuery) return resolve([]);
                fetchWrapper
                    .get(api.searchBase + endpoint, {
                        searchterm
                    })
                    .then(data => {
                        resolve(data || []);
                    });
            });
        }
    });
}
// --------------------------------------- Admin Queries ---------------------------------------

export function useFetchLicenseDataAndSetStoreValues() {
    const [user, setUser] = useAtom(userAtom);
    const [demoMode] = useAtom(demoModeAtom);
    const [currentAuditEntryId, setCurrentAuditEntryId] = useAtom(currentAuditEntryIdAtom);
    const [, setReportDemoMode] = useAtom(reportDemoModeAtom);
    const [, setAuditDemoMode] = useAtom(auditDemoModeAtom);
    const [, setAssistDemoMode] = useAtom(assistDemoModeAtom);
    const [, setEasyDemoModeAtom] = useAtom(easyDemoModeAtom);
    const [, setTranslateDemoModeAtom] = useAtom(translateDemoModeAtom);

    const [, setAvailableAuditEntries] = useAtom(availableAuditEntriesAtom);
    const [, setAuditLicenses] = useAtom(auditLicensesAtom);
    const [, setAssistLicenses] = useAtom(assistLicensesAtom);
    const [, setTranslateEasyLicenses] = useAtom(translateEasyLicenses);
    const [selectedAssistDomain, setSelectedAssistDomain] = useAtom(assistDomainAtom);
    const [, setOrgUsers] = useAtom(orgUsersAtom);
    const [, setTodosData] = useAtom(todosDataAtom);
    const [, setJiraConfig] = useAtom(jiraConfigAtom);
    const [, setUserNotifications] = useAtom(userNotificationsAtom);

    const [isLoading, setIsLoading] = useState(true);

    const {
        data,
        isLoading: queryLoading,
        isError
    } = useQuery({
        queryKey: [api.listAllLicenses, user?.id, user?.isAdmin, demoMode, api.todos],
        enabled: !!user,
        queryFn: () => {
            // in demo mode, no need to fetch, default values are set in useEffect
            if (demoMode) {
                console.log('useFetchLicenseDataAndSetStoreValues | demo mode active');
                return Promise.resolve([null, null, fetchWrapper.get(api.todos)]);
            }

            if (offlineMode) {
                return Promise.resolve([
                    [
                        // assist
                        [
                            {
                                userId: 385,
                                creatorId: 385,
                                creationDate: '2023-02-07T00:00:00.000Z',
                                endDate: '2107-01-27T00:00:00.000Z',
                                accessCounter: 0,
                                lastAccessDate: null,
                                domain: 'eye-able.com',
                                licenseKey: 'fafdce7ba2b97948eqhknedji',
                                testLicense: 0,
                                keyOnly: 0,
                                id: 2420,
                                primaryOrganisation: null,
                                secondaryOrganisation: null
                            }
                        ],
                        // audit
                        [
                            {
                                userId: 385,
                                creatorId: 3,
                                creationDate: '2022-11-14T00:00:00.000Z',
                                endDate: '2040-05-05T00:00:00.000Z',
                                accessCounter: 0,
                                lastAccessDate: null,
                                domains: '.*',
                                id: 106,
                                primaryOrganisation: null,
                                secondaryOrganisation: null
                            }
                        ],
                        // report
                        [
                            {
                                id: 2,
                                domain: 'zugang4all.de',
                                auditInterval: 30,
                                lastTotalIssues: 919,
                                lastOverallScore: 0.625,
                                userId: 3,
                                creatorId: 3,
                                pagesCrawled: 28,
                                cdnDirectory: 'zugang4all.de',
                                conformanceTarget: 'AA',
                                pdfTotalNumber: 2,
                                beingCrawled: 0,
                                maxPages: 50,
                                maxPdfs: 500,
                                errorFlag: '0',
                                brokenLinksFlag: 0,
                                brokenLinksErrorTxt: null,
                                auditErrorTxt: null,
                                pdfErrorTxt: null,
                                primaryOrganisation: 1,
                                secondaryOrganisation: null,
                                lighthouseActive: 1,
                                lighthouseFlag: 3,
                                lighthouseMaxPages: 200,
                                lighthouseDuration: 0,
                                lighthouseLastCrawl: '2023-10-07T00:00:00.000Z',
                                contentActive: '1',
                                contentFlag: 0,
                                contentDuration: null,
                                contentLastCrawl: null,
                                lastRecheckRequest: null,
                                allowedRecheckFrequency: 14,
                                server: 'ALL'
                            }
                        ]
                    ],
                    // orgUsers
                    [demoUser],
                    // TODO: add todos
                    {
                        todos: []
                    }
                ]);
            }

            return Promise.all([
                fetchWrapper.get(api.listAllLicenses),
                fetchWrapper.get(api.orgUsers),
                fetchWrapper.get(api.todos),
                fetchWrapper.get(api.jiraConfig),
                fetchWrapper.get(api.transDomainSearch)
            ]);
        }
    });
    useEffect(() => {
        if (demoMode && !user) {
            return setUser(demoUser);
        }

        if (!user || isError) {
            // no user, no need to set values
            // if isError, the error is thrown and application will show error page
            setIsLoading(false);
            return;
        }

        if (queryLoading) return;

        const licenses = data?.[0] || []; // listAllLicenses

        const assistLicenses = licenses[0] || [];
        const auditLicenses = licenses[1] || [];
        const reportLicenses = licenses[2] || [];
        const translateEasyLicenses = data[4] || [];

        if (!reportLicenses.length) {
            console.log('no reportLicenses, activating report demo mode');
            setAvailableAuditEntries([demoEntry]);
            setReportDemoMode(true);
            setCurrentAuditEntryId(demoEntry.id);
        } else {
            //console.log('setAvailableAuditEntries to', reportLicenses);
            setReportDemoMode(false);
            setAvailableAuditEntries(reportLicenses);

            const firstEntry = reportLicenses[0];

            if (!currentAuditEntryId) {
                console.log('no currentAuditEntryId, set currentAuditEntryId:', firstEntry?.id);
                setCurrentAuditEntryId(firstEntry.id);
            } else {
                // check if selected Domain is in available domains (in case user or domains changed)
                const found = reportLicenses.find(entry => entry.id === currentAuditEntryId);

                if (!found) {
                    console.log('id not found in available entries, set id to:', firstEntry.id);
                    setCurrentAuditEntryId(firstEntry.id);
                }
            }
        }

        if (!assistLicenses.length) {
            console.log('no assistLicenses, activating assist demo mode');
            setAssistLicenses([]);
            setSelectedAssistDomain('testdomain.com');
            setAssistDemoMode(true);
        } else {
            setAssistDemoMode(false);
            setAssistLicenses(assistLicenses);

            const firstDomain = assistLicenses[0].domain;

            if (!selectedAssistDomain) {
                console.log('no selectedAssistDomain, set selectedAssistDomain:', firstDomain);
                setSelectedAssistDomain(firstDomain);
            } else {
                // check if selected Domain is available (in case user or domains changed)
                const found = assistLicenses.find(entry => entry.domain === selectedAssistDomain);

                if (!found) {
                    console.log(
                        'domain not found in available domains, set selectedAssistDomain to:',
                        firstDomain,
                        'assistLicenses: ',
                        assistLicenses,
                        'selectedAssistDomain: ',
                        selectedAssistDomain
                    );
                    setSelectedAssistDomain(firstDomain);
                }
            }
        }

        if (!auditLicenses.length) {
            console.log('no auditLicenses, activating audit demo mode');
            setAuditLicenses([]);
            setAuditDemoMode(true);
        } else {
            setAuditDemoMode(false);
            setAuditLicenses(auditLicenses);
        }

        if (!translateEasyLicenses.length) {
            console.log('no translateEasyLicenses, activating audit demo mode');
            setTranslateEasyLicenses([]);
            setTranslateDemoModeAtom(true);
            setEasyDemoModeAtom(true);
        } else {
            translateEasyLicenses.forEach(entry => {
                if (entry.charLimit && entry.charLimit > 0) {
                    setTranslateDemoModeAtom(false);
                } else if (entry.charLimitEasy && entry.charLimitEasy > 0) {
                    setEasyDemoModeAtom(false);
                }
                setTranslateEasyLicenses(translateEasyLicenses);
            });
        }

        setOrgUsers(data?.[1]?.sort((a, b) => a.firstName.localeCompare(b.firstName)) || [user]); // /organisations/users
        if (data?.[3]) setJiraConfig(data?.[3]?.[0]); // /jira/config
        const todosData = data?.[2] || { todos: {} }; // /todo

        /**
         * type 4: assignee changed,
         * type 6: comment added,
         * type 8: changed description
         */
        const todosNotificationType = [4, 6, 8];

        const notifications = [];
        let assigneeNotifi = null;
        if (demoMode) {
            todosData.then(data => {
                Object.keys(data?.todos).forEach((todoId, index) => {
                    notifications.push({
                        todoId,
                        type: todosNotificationType[index % todosNotificationType.length],
                        modifier: user.id,
                        activityDate: new Date().getTime()
                    });
                });
                setUserNotifications(notifications);

                setTodosData(data);
                setIsLoading(false);
                return;
            });
        } else if (todosData?.todos) {
            // interval: 30 days
            const notificationInterval = new Date();
            notificationInterval.setDate(new Date().getDate() - 30);

            Object.keys(todosData?.todos).forEach(todoId => {
                todosData?.todos[todoId]?.history?.forEach(activity => {
                    // only include history after last notifications check date
                    let shouldNotify = notificationInterval <= new Date(activity.activityDate);

                    // only consider todos assigned to currently logged user
                    shouldNotify = shouldNotify && +todosData?.todos[todoId].assignee === +user.id;

                    // only include history from given list
                    shouldNotify = shouldNotify && todosNotificationType.includes(+activity.type);

                    // only consider history item where current is not modifier
                    shouldNotify = shouldNotify && +activity.modifier !== user.id;

                    // if history item is assignee changed, than check if its assigned to current user
                    if (+activity.type === 4)
                        shouldNotify = shouldNotify && +activity?.props?.assignedTo === user.id;

                    if (shouldNotify) {
                        if (+activity.type === 4 && +activity?.props?.assignedTo === user.id) {
                            if (
                                !assigneeNotifi ||
                                assigneeNotifi.activityDate < activity.activityDate
                            )
                                assigneeNotifi = { ...activity, todoId };
                        } else notifications.push({ ...activity, todoId });
                    }
                });
            });
            if (assigneeNotifi) notifications.push(assigneeNotifi);
            setUserNotifications(notifications);
        }

        setTodosData(todosData);
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data,
        demoMode,
        queryLoading,
        isError,
        user,
        setAssistDemoMode,
        setAssistLicenses,
        setAuditDemoMode,
        setAuditLicenses,
        setOrgUsers,
        setTodosData,
        setJiraConfig,
        setReportDemoMode,
        setUserNotifications,
        setUser,
        selectedAssistDomain,
        setSelectedAssistDomain
    ]);

    return { isLoading, isError };
}

export function useReloadLicenseData() {
    const queryClient = useQueryClient();

    return () =>
        console.log('useReloadLicenseData invalidateQueries') ||
        queryClient.invalidateQueries({
            queryKey: [api.listAllLicenses]
        });
}

export function useFetchReportAndHistory(auditEntry) {
    const cdnDir = auditEntry?.cdnDirectory;

    const fullCdnPath = cdnDir
        ? reportStorageUrl + cdnDir + (cdnDir.endsWith('/') ? '' : '/')
        : null;

    const queries = useQueries({
        queries: [
            {
                enabled: !!fullCdnPath,
                queryKey: [queryKeys.REPORT, fullCdnPath],
                queryFn: async () => {
                    if (offlineMode) return (await import('/mockData/report.json')).default;
                    else return fetchWrapper.get(`${fullCdnPath}report.json`);
                }
            },
            {
                enabled: !!fullCdnPath,
                queryKey: [queryKeys.HISTORY, fullCdnPath],
                queryFn: async () => {
                    if (offlineMode) return (await import('/mockData/history.json')).default;
                    else return fetchWrapper.get(`${fullCdnPath}history.json`);
                }
            }
        ]
    });

    const isLoading = queries.some(query => query.isLoading);
    const isError = queries.some(query => query.isError);

    return {
        isLoading,
        isError,
        report: queries?.[0]?.data,
        history: queries?.[1]?.data
    };
}

export function useFetchRules() {
    const { i18n, ready } = useTranslation();
    const lng = i18n.resolvedLanguage;

    const queries = useQueries({
        queries: [
            {
                enabled: !!(ready && lng),
                queryKey: [queryKeys.EYEABLE_RULES, lng],
                queryFn: async () => {
                    if (offlineMode)
                        return (await import(`../../mockData/ea_rules_${lng}.json`)).default;
                    else
                        return fetchWrapper.get(
                            `${storageUrl}audit-json-files/ea_rules_${lng}.json`
                        );
                }
            },
            {
                enabled: !!(ready && lng),
                queryKey: [queryKeys.WCAG_RULES, lng],
                queryFn: async () => {
                    if (offlineMode)
                        return (await import(`../../mockData/wcag_rules_${lng}.json`)).default;
                    else
                        return fetchWrapper.get(
                            `${storageUrl}audit-json-files/wcag_rules_${lng}.json`
                        );
                }
            },
            {
                queryKey: [queryKeys.EYEABLE_RULES_META],
                queryFn: async () => {
                    if (offlineMode)
                        return (await import(`../../mockData/ea_rules_meta.json`)).default;
                    else
                        return fetchWrapper.get(`${storageUrl}audit-json-files/ea_rules_meta.json`);
                }
            },
            {
                queryKey: [queryKeys.WCAG_RULES_META],
                queryFn: async () => {
                    if (offlineMode)
                        return (await import(`../../mockData/wcag_rules_meta.json`)).default;
                    else
                        return fetchWrapper.get(
                            `${storageUrl}audit-json-files/wcag_rules_meta.json`
                        );
                }
            }
        ]
    });

    const isLoading = queries.some(query => query.isLoading);
    const isError = queries.some(query => query.isError);

    const eaRules = queries?.[0]?.data,
        wcagRules = queries?.[1]?.data,
        eaRulesMeta = queries?.[2]?.data,
        wcagRulesMeta = queries?.[3]?.data;

    if (eaRules && wcagRules && eaRulesMeta && wcagRulesMeta) {
        // Merge eaRules with meta data
        for (const ruleId in eaRules) {
            if (eaRulesMeta[ruleId]) {
                eaRules[ruleId] = { ...eaRules[ruleId], ...eaRulesMeta[ruleId] };
            }
        }

        // Merge wcagRules with meta data
        for (const ruleId in wcagRules) {
            if (wcagRulesMeta[ruleId]) {
                wcagRules[ruleId] = { ...wcagRules[ruleId], ...wcagRulesMeta[ruleId] };
            }
        }
    }

    return {
        isLoading,
        isError,
        eaRules,
        wcagRules
    };
}

export function useFetchAccessibilityData({ auditEntry, wcagLevel, onDataChanged }) {
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);
    const [accessibilityData, setAccessibilityData] = useState();

    const entry = auditEntry || currentAuditEntry;

    const conformanceTarget = wcagLevel || entry?.complianceTarget;

    const {
        report,
        history,
        isLoading: reportAndHistoryLoading,
        isError: reportAndHistoryError
    } = useFetchReportAndHistory(entry);

    const { wcagRules, eaRules, isLoading: rulesLoading, isError: rulesError } = useFetchRules();

    useEffect(() => {
        if (
            !conformanceTarget ||
            rulesLoading ||
            rulesError ||
            reportAndHistoryLoading ||
            reportAndHistoryError ||
            accessibilityData?.id === entry?.id
        )
            return;

        // Calculate the final data
        const data = calculateAccessibilityData({
            report,
            history,
            conformanceTarget,
            eaRules,
            wcagRules,
            auditEntry: entry
        });

        setAccessibilityData(data);
        onDataChanged?.(data);
    }, [
        onDataChanged,
        accessibilityData,
        setAccessibilityData,
        conformanceTarget,
        rulesLoading,
        rulesError,
        reportAndHistoryLoading,
        reportAndHistoryError,
        eaRules,
        wcagRules,
        currentAuditEntry,
        history,
        report,
        entry
    ]);

    return {
        data: accessibilityData,
        isLoading:
            rulesLoading ||
            reportAndHistoryLoading ||
            (!accessibilityData && !reportAndHistoryError && !rulesError),
        isError: reportAndHistoryError || rulesError
    };
}

// returns the data for all available reports (first 25 entries)
export function useFetchReportDataForAllDomains() {
    const [availableAuditEntries] = useAtom(availableAuditEntriesAtom);
    const [reportDemoMode] = useAtom(reportDemoModeAtom);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const { eaRules, wcagRules, isError: rulesError, isLoading: rulesLoading } = useFetchRules();

    const _queries = useMemo(() => {
        return availableAuditEntries.slice(0, 25).map(entry => {
            const cdnDir = entry.cdnDirectory;
            const fullCdnPath = reportStorageUrl + cdnDir + (cdnDir.endsWith('/') ? '' : '/');

            return {
                queryKey: [queryKeys.REPORT, fullCdnPath],
                queryFn: async () => {
                    if (offlineMode) return (await import('/mockData/report.json')).default;
                    else return fetchWrapper.get(`${fullCdnPath}report.json`);
                },
                enabled: !!fullCdnPath
            };
        });
    }, [availableAuditEntries]);

    const queries = useQueries({
        queries: _queries
    });

    const queriesLoading = rulesLoading || queries.some(query => query.isLoading);

    const queriesError = rulesError || queries.some(query => query.isError);

    const AllReportsData = useMemo(() => {
        if (reportDemoMode) {
            setIsLoading(false);
            setIsError(false);
            return undefined;
        }

        if (!availableAuditEntries?.length) {
            setIsLoading(true);
            setIsError(false);
            return undefined;
        }

        if (queriesError) {
            setIsError(true);
            setIsLoading(false);
            return undefined;
        } else if (queriesLoading) {
            setIsLoading(true);
            setIsError(false);
            return undefined;
        } else {
            const allReports = {};

            let i = 0;

            for (const query of queries) {
                if (query.isError) {
                    i++;
                    continue;
                }
                const report = query.data;
                const conformanceTarget = availableAuditEntries[i].complianceTarget || 'AA';
                const auditEntry = availableAuditEntries[i];

                let data = {
                    ...auditEntry,
                    conformanceTarget
                };

                if (report) {
                    data = calculateAccessibilityData({
                        report,
                        history: null,
                        conformanceTarget,
                        eaRules,
                        wcagRules,
                        auditEntry
                    });
                }

                allReports[auditEntry.domain] = data;

                i++;
            }

            setIsLoading(false);
            setIsError(false);

            //console.log('AllReportsData', allReports);
            return allReports;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queriesLoading, queriesError, reportDemoMode, availableAuditEntries]);

    return {
        AllReportsData,
        isLoading,
        isError
    };
}

export function useFetchPdfErrorText() {
    const { i18n } = useTranslation();
    const lng = i18n.resolvedLanguage;

    const handleResponse = response => {
        return response.text().then(text => {
            let data = text;

            if (text && response.headers.get('Content-Type')?.includes('json')) {
                try {
                    data = JSON.parse(text);
                } catch (e) {
                    console.info('Error parsing JSON:', e);
                    return Promise.reject({
                        ok: false,
                        status: 500,
                        message: 'Invalid JSON response'
                    });
                }
            }

            return data;
        });
    };

    const fallbackGetter = async () => {
        try {
            const response = await fetch(`${storageUrl}pdf_error_text_${lng}_v2.json`);
            if (!response.ok) {
                throw new Error(`Error loading ${storageUrl}pdf_error_text_${lng}_v2.json`);
            }
            return handleResponse(response);
        } catch (e) {
            const response = await fetch(`${storageUrl}pdf_error_text_en_v2.json`);
            if (!response.ok) {
                return Promise.reject(response);
            }
            return handleResponse(response);
        }
    };

    return useQuery({
        queryKey: [queryKeys.PDF_ERROR_TEXT, lng],
        queryFn: fallbackGetter,
        retry: false
    });
}

export function useFetchFoundUrls(enabled = true) {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        enabled: !!(enabled && fullCdnPath),
        queryKey: [queryKeys.FOUND_URLS, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}foundUrls.json`)
    });
}

export function useFetchUrlMap(enabled = true) {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        enabled: !!(enabled && fullCdnPath),
        queryKey: [queryKeys.URL_MAP, fullCdnPath],
        queryFn: async () => {
            if (offlineMode) return (await import('/mockData/urlMap.json')).default;
            else return fetchWrapper.get(`${fullCdnPath}urlMap.json`);
        }
    });
}
export function useFetchFonts(enabled = true) {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        enabled: !!(enabled && fullCdnPath),
        queryKey: [queryKeys.FONTS, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}fonts.json`)
    });
}

export function useFetchLighthouseSummary() {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        queryKey: [queryKeys.LIGHTHOUSE_SUMMARY, fullCdnPath],
        queryFn: async () => {
            if (offlineMode) return (await import('/mockData/lighthouseSummary.json')).default;
            else return fetchWrapper.get(`${fullCdnPath}lighthouse/lighthouseSummary.json`);
        }
    });
}

export function useFetchLighthouseReportForPage(filename) {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        queryKey: [queryKeys.LIGHTHOUSE, fullCdnPath, filename],
        queryFn: async () => {
            if (offlineMode) return (await import('/mockData/lighthouseDetails28.json')).default;
            else return fetchWrapper.get(`${fullCdnPath}lighthouse/${filename}.json`);
        }
    });
}

export function useFetchPdfs(userPdfs = false) {
    const [fullCdnPath] = useAtom(userPdfs ? fullUsersCdnPathAtom : fullCdnPathAtom);
    return useQuery({
        queryKey: [queryKeys.PDFS, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}pdfs.json`)
    });
}

export function useFetchJobsSimpleLangDocument() {
    return useQuery({
        queryKey: [api.jobsSimpleLangDocument],
        queryFn: () => fetchWrapper.get(api.jobsSimpleLangDocument)
    });
}

export function useFetchPageDetailReport(pageIndex) {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    const url = `${fullCdnPath}reports/${pageIndex}.json`;

    return useQuery({
        enabled: pageIndex >= 0,
        queryKey: [queryKeys.PAGE_DETAIL_REPORT, fullCdnPath, pageIndex, url],
        queryFn: () => fetchWrapper.get(url)
    });
}

export function useFetchPdfDetails(pdfName, userPdf = false) {
    const [fullCdnPath] = useAtom(userPdf ? fullUsersCdnPathAtom : fullCdnPathAtom);

    return useQuery({
        queryKey: [queryKeys.PDFS, fullCdnPath, pdfName],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}PDF_Reports/${pdfName}.json`)
    });
}

export function useUpdateConformanceTarget() {
    const [user] = useAtom(userAtom);
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);
    const [, setAvailableAuditEntries] = useAtom(availableAuditEntriesAtom);
    const [, setRenderKey] = useAtom(rerenderLayoutAtom);

    return useMutation({
        mutationKey: [api.conformanceTarget, currentAuditEntry?.id, user?.id],
        mutationFn: newTarget =>
            fetchWrapper.put(api.conformanceTarget, {
                newTarget: newTarget,
                entryId: currentAuditEntry.id
            }),
        onSuccess: (_, newTarget) => {
            setAvailableAuditEntries(oldData => {
                // Create a new array with updated entries
                const updatedData = oldData.map(entry => {
                    // Only return a new object if the entry needs updating
                    if (entry.id === currentAuditEntry.id) {
                        return {
                            ...entry, // Spread the existing properties
                            complianceTarget: newTarget // Update the complianceTarget
                        };
                    }
                    return entry; // Return other entries unchanged
                });

                // Return the updated array
                return updatedData;
            });

            localStorage.setItem('reload', new Date().getTime());

            setRenderKey(new Date().getTime());
        }
    });
}

export function useFetchReportSettings() {
    const [settings, setSettings] = useState();
    const [_isLoading, setIsLoading] = useState(true);
    const [_isError, setIsError] = useState(false);
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    const { data, isLoading, isError } = useQuery({
        staleTime: 1000 * 90, // 90 seconds
        enabled: !!fullCdnPath,
        queryKey: [queryKeys.REPORT_SETTINGS, fullCdnPath],
        queryFn: () => fetchWrapper.get(fullCdnPath + 'config.json')
    });

    useEffect(() => {
        if (isError) {
            setIsError(true);
            setIsLoading(false);
        } else if (!isError && !isLoading) {
            if (data) setSettings({ ...defaultReportSettings, ...data });
            else setSettings({ ...defaultReportSettings });

            setIsLoading(false);
            setIsError(false);
        }
    }, [isError, isLoading, data]);

    return { data: settings, isLoading: _isLoading, isError: _isError };
}

export function useFetchFoundUrlsHistory() {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        queryKey: [queryKeys.FOUND_URLS_HISTORY, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}foundUrlsHistory.json`)
    });
}

export function useFetchNotifications() {
    const { i18n } = useTranslation();
    let lng = i18n.resolvedLanguage;

    if (lng === 'de') lng = 'de';
    else lng = 'en';

    return useQuery({
        queryKey: [queryKeys.NOTIFICATIONS, lng],
        queryFn: () =>
            fetchWrapper.get(
                import.meta.env.DEV
                    ? `/locales/${lng}/notifications.json`
                    : `/${version}/locales/${lng}/notifications.json`
            )
    });
}

export function useFetchReportEntryConfig() {
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);
    return useQuery({
        enabled: !!currentAuditEntry?.id,
        queryKey: [api.reportEntryConfig, currentAuditEntry?.id],
        queryFn: () =>
            fetchWrapper.get(api.reportEntryConfig, {
                entryid: currentAuditEntry?.id
            })
    });
}

/**
 * updates the given report settings
 * only pass the changed values so that the backend can merge the new values with the old ones
 */
export function useUpdateReportSettings() {
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);
    const queryClient = useQueryClient();
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useMutation({
        mutationFn: settingsToUpdate => {
            settingsToUpdate.entryId = currentAuditEntry.id;
            return fetchWrapper.put(api.reportEntryConfig, settingsToUpdate);
        },
        onSuccess: data => {
            console.log('useUpdateReportSettings onSuccess', data);
            queryClient.setQueryData(
                [api.reportEntryConfig, currentAuditEntry?.id],
                data?.newConfig
            );

            queryClient.invalidateQueries({
                queryKey: [queryKeys.REPORT_SETTINGS, fullCdnPath]
            });
        }
    });
}

// 
export function useSamlLogin(){

    const [, setUser] = useAtom(userAtom);
    const [, setTokenExpires] = useAtom(tokenExpiresAtom);

    return useMutation({
        mutationFn: ({ token }) => {
            
            
            return fetch(api.samlLogin, {
                mode: 'cors',
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token
                })
            }).then(async response => {
                if (!response.ok || response.status > 299) {
                    // Extract and log the error message from the response body
                    const errorData = await response.json();
                    console.log('Error response from server:', errorData.error);

                    // If the backend provides a specific error message, throw it
                    if (errorData.error) {
                        throw new Error(errorData.error);
                    }

                    // Otherwise, throw a generic error message
                    throw new Error('Login failed due to an unknown error.');
                }

                // save the dev token for development in the browser
                if (!import.meta.env.PROD && response.headers.get('X-Dev-Token')) {
                    localStorage.setItem('X-Dev-Token', response.headers.get('X-Dev-Token'));
                }


                const user = await response.json();
            
                user.fullName = `${user.firstName + ' ' || ''}${user.lastName || ''}`;

                console.log("User from queries : ", user)
                setUser(user);
                setTokenExpires(user.tokenExpires);

                return user;
            })
        }
    });

}

export function useLoginUser() {
    const [, setUser] = useAtom(userAtom);
    const [, setTokenExpires] = useAtom(tokenExpiresAtom);

    return useMutation({
        mutationFn: ({ password, email }) => {
            if (offlineMode) {
                const user = demoUser;

                setUser(user);
                setTokenExpires(user.tokenExpires);

                return Promise.resolve(user);
            }

            return fetch(api.login, {
                mode: 'cors',
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password
                })
            }).then(async response => {
                if (!response.ok || response.status > 299) {
                    throw new Error('Login failed');
                }

                // save the dev token for development in the browser
                if (!import.meta.env.PROD && response.headers.get('X-Dev-Token')) {
                    localStorage.setItem('X-Dev-Token', response.headers.get('X-Dev-Token'));
                }

                const user = await response.json();

                user.fullName = `${user.firstName + ' ' || ''}${user.lastName || ''}`;

                console.log('useLoginUser', user);

                setUser(user);
                setTokenExpires(user.tokenExpires);

                return user;
            });
        }
    });
}

export function useChangePasswordMutation() {
    return useMutation({
        mutationFn: ({ currentPassword, newPassword, token }) =>
            fetchWrapper.post(api.changePwd, {
                currentPassword,
                newPassword,
                token
            })
    });
}

export function usePasswordLostMutation() {
    return useMutation({
        mutationFn: ({ email }) =>
            fetchWrapper.post(api.pwLost, {
                email
            })
    });
}

export function useFetchWhoami() {
    const [user, setUser] = useAtom(userAtom);
    const [tokenExpires, setTokenExpires] = useAtom(tokenExpiresAtom);
    const [demoMode] = useAtom(demoModeAtom);

    return useQuery({
        queryKey: [api.whoami, tokenExpires, user?.id, demoMode],
        retry: 1,
        queryFn: () => {
            return new Promise((resolve, reject) => {
                if (demoMode || offlineMode) {
                    console.log('useFetchWhoami | demo mode active');
                    setTokenExpires(demoUser.tokenExpires);
                    setUser(demoUser);
                    return resolve(demoUser);
                }

                if (user?.id && user.tokenExpires) {
                    return resolve(user);
                }

                // if no user or tokenExpires, we need to login again
                if (!tokenExpires) {
                    console.log('No tokenExpires');
                    reject('No tokenExpires');
                } else if (
                    Number(tokenExpires) <
                    Number((new Date().getTime() / 1000).toFixed(0)) - 14400
                ) {
                    // make sure the token is not expired or will expire in the next 4 hours
                    console.log('Token expired');
                    reject('Token expired');
                } else {
                    fetchWrapper
                        .get(api.whoami)
                        .then(data => {
                            data.fullName = `${data.firstName + ' ' || ''}${data.lastName || ''}`;
                            setUser(data);
                            //setTokenExpires(data.tokenExpires); // tokenExpires is not returned by whoami, we only need the already saved value from login

                            // if the user opened the login page, we redirect him to the main page
                            if (window.location.pathname.includes('/authentication/login')) {
                                window.location.assign('/');
                            }
                            resolve(data);
                        })
                        .catch(error => {
                            console.error('whoami error | Error fetching userdata', error);
                            reject('Error fetching userdata');
                        });
                }
            });
        }
    });
}

export function useSignupUser() {
    return useMutation({
        mutationFn: body => fetchWrapper.post(api.signup, body)
    });
}

export function useCreateUser() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: body => fetchWrapper.post(api.users, body),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [api.orgUsers]
            });
            queryClient.invalidateQueries({
                queryKey: [api.users]
            });
            queryClient.invalidateQueries({
                queryKey: ['searchAdmin', 'users']
            });
        }
    });
}

export function useRemoveOrgUser() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteApiKey => {
            return fetchWrapper.delete(api.users, {
                deleteApiKey
            });
        },
        onSuccess: (data, userId) => {
            console.log('useRemoveOrgUser', data, userId);
            queryClient.invalidateQueries({
                queryKey: [api.orgUsers]
            });
            queryClient.invalidateQueries({
                queryKey: [api.users]
            });
        }
    });
}

export function useUpdateUser() {
    const [user, setUser] = useAtom(userAtom);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: body => {
            return fetchWrapper.put(api.users, body);
        },
        onSuccess: data => {
            console.log('useUpdateUser', data);

            if (user.id === data?.id) {
                queryClient.setQueryData([api.whoami], oldData => {
                    return {
                        ...oldData,
                        ...data
                    };
                });
                setUser(oldData => {
                    return {
                        ...oldData,
                        ...data
                    };
                });
            }

            queryClient.invalidateQueries({
                queryKey: [api.orgUsers]
            });
            queryClient.invalidateQueries({
                queryKey: [api.users]
            });
            queryClient.invalidateQueries({
                queryKey: ['searchAdmin', 'users']
            });
        }
    });
}

export function useFetchLicenseConstraints(userid) {
    return useQuery({
        enabled: !!userid,
        queryKey: [api.licenseConstraints, userid],
        queryFn: () =>
            fetchWrapper.get(api.licenseConstraints, {
                userid: userid
            })
    });
}

export function usePostLicenseConstraints() {
    const queryClient = useQueryClient();
    const notification = useNotification();

    return useMutation({
        mutationFn: ({ user, licenseData }) => {
            console.log('licenseData query', licenseData);
            return fetchWrapper.post(api.licenseConstraints, {
                licenseData,
                userId: user.id
            });
        },
        onSuccess: (data, { user }) => {
            console.log('usePostLicenseConstraints', data, user.id);
            queryClient.setQueryData([api.licenseConstraints, user.id], data);
            queryClient.invalidateQueries({
                queryKey: [api.orgUsers]
            });
        },
        onError: error => {
            console.error('usePostLicenseConstraints failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useRemoveLicenseConstraints() {
    const queryClient = useQueryClient();
    const notification = useNotification();

    return useMutation({
        mutationFn: ({ userId }) => {
            return fetchWrapper.delete(api.licenseConstraints, {
                userId
            });
        },
        onSuccess: (data, { userId }) => {
            console.log('useRemoveLicenseConstraints', data, userId);
            queryClient.invalidateQueries({
                queryKey: [api.licenseConstraints, userId]
            });
            queryClient.invalidateQueries({
                queryKey: [api.orgUsers]
            });
        },
        onError: error => {
            console.error('useRemoveLicenseConstraints failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useFetchOrgUsers() {
    const [user] = useAtom(userAtom);
    const [demoMode] = useAtom(demoModeAtom);

    return useQuery({
        queryKey: [api.orgUsers, user.primaryOrganisation, demoMode],
        queryFn: () =>
            demoMode
                ? Promise.resolve([demoUser])
                : fetchWrapper.get(api.orgUsers, {
                      orgId: user.primaryOrganisation
                  })
    });
}

// fetches all organisations for the current user or all for admins if no id is provided
export function useFetchOrganisations(id = 'all') {
    return useQuery({
        queryKey: [api.organisations, id],
        queryFn: () => fetchWrapper.get(api.organisations, id === 'all' ? {} : { id })
    });
}

export function useFetchOrgLicenses() {
    const [orgLicenses, setOrgLicenses] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { data, isLoading: queryLoading } = useQuery({
        queryKey: [api.orgLicenses],
        queryFn: () => fetchWrapper.get(api.orgLicenses)
    });

    useEffect(() => {
        if (!queryLoading) {
            setOrgLicenses({
                assistLicenses: data[0] || [],
                auditLicenses: data[1] || [],
                reportLicenses: data[2] || []
            });
            setIsLoading(false);
        }
    }, [data, queryLoading]);

    return { data: orgLicenses, isLoading };
}

export function usePostSupportRequest() {
    const { t, i18n } = useTranslation();
    const notification = useNotification();
    const [user] = useAtom(userAtom);

    return useMutation({
        mutationFn: message =>
            fetchWrapper.post(api.supportMail, {
                mailLang: user?.mailLang || i18n.resolvedLanguage,
                comment: message
            }),
        onSuccess: data => {
            console.log('usePostSupportRequest', data);
            notification.success(t('supportRequestSent'));
        },
        onError: data => {
            console.error('usePostSupportRequest failed', data);
            notification.error(t('supportRequestFailed'));
        }
    });
}

export function usePostOfferRequest() {
    const { t, i18n } = useTranslation();
    const notification = useNotification();
    const [user] = useAtom(userAtom);

    return useMutation({
        mutationFn: message =>
            fetchWrapper.post(api.offerRequest, {
                mailLang: user?.mailLang || i18n.resolvedLanguage,
                comment: message
            }),
        onSuccess: data => {
            console.log('usePostOfferRequest', data);
            notification.success(t('supportRequestSent'));
        },
        onError: data => {
            console.error('usePostOfferRequest failed', data);
            notification.error(t('supportRequestFailed'));
        }
    });
}

export function usePostAssistTrialLicense() {
    const [user] = useAtom(userAtom);
    const { i18n } = useTranslation();

    const defaults = {
        newUser: null,
        createServerConfig: true,
        mailLang: user?.mailLang || i18n.resolvedLanguage,
        userId: user?.id
    };

    return useMutation({
        mutationFn: body =>
            fetchWrapper.post(api.assistTestLicense, {
                ...defaults,
                ...body
            })
    });
}

function extractJsonObject(str, variableName = 'eyeAble_pluginConfig') {
    const variableDeclaration = `${variableName} =`;
    let startIndex = str.indexOf(variableDeclaration);
    if (startIndex === -1) {
        return null; // Variable name not found
    }
    startIndex += str.substring(startIndex).indexOf('{'); // Find the opening brace

    let braceCount = 1; // Start with 1 to count the opening brace
    let currentIndex = startIndex + 1;

    while (currentIndex < str.length && braceCount > 0) {
        const char = str[currentIndex];
        if (char === '{') {
            braceCount++;
        } else if (char === '}') {
            braceCount--;
        }
        currentIndex++;
    }

    if (braceCount !== 0) {
        throw new Error('Mismatched braces in the JSON object');
    }

    // Extract the JSON string
    const jsonString = str.substring(startIndex, currentIndex);
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        throw new Error('Failed to parse the JSON object: ' + e.message);
    }
}

/**
 * update inputData state from output of fetchCDNData()
 */
function buildFormValuesArr(cdnData = {}) {
    let updatedInputValues = clone(inputValues);
    updatedInputValues['extras'] = {};

    if (!cdnData) return updatedInputValues;

    if (cdnData.customFunctionPosition) {
        Object.keys(updatedInputValues.funcPosition).forEach(funcKey => {
            if (cdnData.customFunctionPosition[funcKey]) {
                updatedInputValues.funcPosition[funcKey].value =
                    cdnData.customFunctionPosition[funcKey];
                delete cdnData.customFunctionPosition[funcKey];
            }
        });
    }

    Object.keys(updatedInputValues).forEach(catKey => {
        if (catKey !== 'funcPosition') {
            Object.keys(updatedInputValues[catKey]).forEach(fieldKey => {
                if (cdnData[fieldKey])
                    updatedInputValues[catKey][fieldKey].value = cdnData[fieldKey];
                delete cdnData[fieldKey];
            });
        }
    });

    updatedInputValues['extras'] = cdnData;
    return updatedInputValues;
}

export function useFetchAssistConfig(selectedDomain, refetchKey = 0) {
    const [assistDemoMode] = useAtom(assistDemoModeAtom);
    return useQuery({
        queryKey: [api.assistConfig, selectedDomain, assistDemoMode, refetchKey],
        enabled: !!selectedDomain,
        queryFn: () => {
            return new Promise((resolve, rejcet) => {
                if (assistDemoMode) return resolve(buildFormValuesArr());
                fetchWrapper
                    .get(
                        `https://cdn.eye-able.com/configs/${selectedDomain}.js?version=${Date.now()}`,
                        { domain: selectedDomain }
                    )
                    .then(data => {
                        console.log("useFetchAssistConfig", data)
                        const obj = extractJsonObject(data);
                        return resolve(buildFormValuesArr(obj));
                    })
                    . catch((error) => {
                        console.error('useFetchAssistConfig failed', error);
                        return rejcet(error);
                    });
            });
        }
    });
}

export function useUpdateAssistConfig() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: body => {
            return fetchWrapper.post(api.assistConfig, body);
        },
        onSuccess: data => {
            console.log('useUpdateAssistConfig success', data);
            queryClient.invalidateQueries({ queryKey: [api.assistConfig] });
        }
    });
}

export function usePostTodo() {
    const [selectedDomain] = useAtom(selectedDomainAtom);
    const [, setTodosData] = useAtom(todosDataAtom);

    return useMutation({
        mutationFn: body => {
            return fetchWrapper.post(api.todo, { ...body, domain: selectedDomain });
        },
        onSuccess: data => {
            console.log('usePostTodo', data);
            if (data?.newId) {
                setTodosData(data.newTodos);
                return data.newTodos;
            }
        }
    });
}

export function useUpdateTodos() {
    const [, setTodosData] = useAtom(todosDataAtom);

    return useMutation({
        mutationFn: body => {
            console.log('useUpdateTodos', body);

            return fetchWrapper.put(api.todos, body);
        },
        onSuccess: data => {
            console.log('useUpdateTodos success', data);
            delete data.message;
            setTodosData(data);
            return data;
        }
    });
}

export function useRemoveTodo() {
    const [todosData, setTodosData] = useAtom(todosDataAtom);

    return useMutation({
        mutationFn: todoId => {
            return fetchWrapper.delete(api.todo, {
                todoId: todoId
            });
        },
        onSuccess: (data, todoId) => {
            console.log('useRemoveTodo', data, todoId);

            const newData = clone(todosData);

            delete newData?.todos[todoId];
            setTodosData(newData);
            return newData;
        }
    });
}

export function useFetchSpellCheckingData(enabled = true) {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        enabled: !!fullCdnPath && enabled,
        queryKey: [queryKeys.SPELL_CHECKING, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}Content_Analyse/spellchecker.json`)
    });
}

export function useFetchSpellCheckingHistory() {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);

    return useQuery({
        queryKey: [queryKeys.SPELL_CHECKING_HISTORY, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}Content_Analyse/spellchecker_history.json`)
    });
}

export function useFetchSpellCheckingReadabilityScore() {
    const [fullCdnPath] = useAtom(fullCdnPathAtom);
    return useQuery({
        queryKey: [queryKeys.SPELL_CHECKING_READABILITY_SCORE, fullCdnPath],
        queryFn: () => fetchWrapper.get(`${fullCdnPath}Content_Analyse/readabilityScores.json`)
    });
}

export function useFetchGeneratorEntries(cdnDirectory) {
    return useQuery({
        queryKey: [api.accessibilityStatement, cdnDirectory],
        queryFn: () => fetchWrapper.get(api.accessibilityStatement, { cdnDirectory })
    });
}

export function usePostGeneratorEntry() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: body => fetchWrapper.post(api.accessibilityStatement, body),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [api.accessibilityStatement]
            });
        }
    });
}

export function useFetchGeneratorFile({ cdnDirectory, fileName }) {
    return useQuery({
        enabled: !!cdnDirectory && !!fileName,
        queryKey: [queryKeys.GENERATOR_FILES, cdnDirectory, fileName],
        queryFn: () =>
            fetchWrapper.get(
                `${reportStorageUrl}${cdnDirectory}/accessibilityStatement/${fileName}.json`
            )
    });
}

export function useFetchJiraConfig() {
    return useQuery({
        queryKey: [api.jiraConfig],
        queryFn: () => fetchWrapper.get(api.jiraConfig)
    });
}

export function usePostJiraConfig() {
    const notification = useNotification();
    const { t } = useTranslation();
    const [, setJiraConfig] = useAtom(jiraConfigAtom);

    return useMutation({
        mutationFn: body => {
            return fetchWrapper.post(api.jiraConfig, body);
        },
        onSuccess: data => {
            setJiraConfig(data);
            return data;
        },
        onError: async error => {
            const errorText = await error.json();
            let notifyText = t('jiraConfigPostFailed');
            if (errorText && errorText?.message) notifyText += ': ' + t(errorText.message);
            notification.error(notifyText);

            return null;
        }
    });
}

export function useUpdateJiraConfig() {
    const notification = useNotification();
    const { t } = useTranslation();
    const [, setJiraConfig] = useAtom(jiraConfigAtom);

    return useMutation({
        mutationFn: body => {
            return fetchWrapper.put(api.jiraConfig, body);
        },
        onSuccess: data => {
            setJiraConfig(data);
            return data;
        },
        onError: async error => {
            const errorText = await error.json();
            let notifyText = t('jiraConfigUpdateFailed');
            if (errorText && errorText?.message) notifyText += ': ' + t(errorText.message);
            notification.error(notifyText);

            return null;
        }
    });
}

export function useRemoveJiraConfig() {
    const notification = useNotification();
    const [, setJiraConfig] = useAtom(jiraConfigAtom);
    const [, setTodosData] = useAtom(todosDataAtom);

    return useMutation({
        mutationFn: () => {
            return fetchWrapper.delete(api.jiraConfig);
        },
        onSuccess: data => {
            console.log('useRemoveJiraConfig', data);
            setTodosData(data);
            setJiraConfig(undefined);
        },
        onError: error => {
            console.error('useRemoveJiraConfig failed', error);
            notification.error(error?.message || error);
        }
    });
}

export function useFetchJiraProjects() {
    const [jiraConfig] = useAtom(jiraConfigAtom);
    return useQuery({
        enabled: !!jiraConfig,
        queryKey: [api.jiraProjects],
        queryFn: () => fetchWrapper.get(api.jiraProjects)
    });
}

export function useFetchJiraUsers() {
    const [jiraConfig] = useAtom(jiraConfigAtom);
    return useQuery({
        enabled: !!jiraConfig,
        queryKey: [api.jiraUsers],
        queryFn: () => fetchWrapper.get(api.jiraUsers)
    });
}

export function useFetchJiraUsersProjectAssignable(projectKeys) {
    return useQuery({
        enabled: !!projectKeys,
        queryKey: [api.jiraUsers, projectKeys],
        queryFn: () =>
            fetchWrapper.get(api.jiraUsersProjectAssignable, { projectKeys: projectKeys })
    });
}

export function useFetchJiraIssueTypes(projectKey) {
    return useQuery({
        enabled: !!projectKey,
        queryKey: [api.jiraIssueTypes, projectKey],
        queryFn: () =>
            fetchWrapper.get(api.jiraIssueTypes, {
                projectKey: projectKey
            })
    });
}

export function useFetchJiraComponents(projectKey, enabled) {
    return useQuery({
        enabled,
        queryKey: [api.jiraComponents, projectKey],
        queryFn: () =>
            fetchWrapper.get(api.jiraComponents, {
                projectKey: projectKey
            })
    });
}

export function useFetchJiraIssueMetaData(projectKey, issuetypeId) {
    return useQuery({
        enabled: !!projectKey && issuetypeId !== 0,
        queryKey: [api.jiraIssueMetaData, projectKey, issuetypeId],
        queryFn: () =>
            fetchWrapper.get(api.jiraIssueMetaData, {
                projectKey,
                issuetypeId
            })
    });
}

export function usePostJiraIssue() {
    return useMutation({
        mutationFn: body => {
            return fetchWrapper.post(api.jiraIssue, body);
        },
        onSuccess: data => {
            return data;
        }
    });
}

export function usePostJiraIssueComment() {
    return useMutation({
        mutationFn: body => {
            return fetchWrapper.post(api.jiraIssueComment, body);
        },
        onSuccess: data => {
            return data;
        }
    });
}

export function usePostMulJiraIssues() {
    return useMutation({
        mutationFn: body => {
            return fetchWrapper.post(api.jiraIssues, body);
        },
        onSuccess: data => {
            return data;
        }
    });
}

export function useFetchTranslationDomains() {
    const [user] = useAtom(userAtom);
    return useQuery({
        queryKey: [api.transDomainSearch],
        queryFn: () => fetchWrapper.get(api.transDomainSearch),
        enabled: !!user?.id,
        refetchInterval: 5 * 60 * 1000 // refetch every 5 minutes
    });
}

export function usePreFetchTranslationDomains() {
    const queryClient = useQueryClient();
    const [user] = useAtom(userAtom);

    useEffect(() => {
        queryClient.prefetchQuery({
            enabled: !!user?.id,
            queryKey: [api.transDomainSearch],
            queryFn: () => fetchWrapper.get(api.transDomainSearch)
        });
    }, [queryClient, user]);
}

export function useFetchTranslationLangPairs(params) {
    return useQuery({
        enabled: !!params.domainId,
        queryKey: [api.transDomainLangPairs, params],
        queryFn: () => fetchWrapper.get(api.transDomainLangPairs, params),
        refetchInterval: 5 * 60 * 1000 // refetch every 5 minutes
    });
}

export function useFetchAllDomainTranslations(params) {
    return useQuery({
        queryKey: [api.transDomainAllTranslation, params],
        queryFn: () => fetchWrapper.get(api.transDomainAllTranslation, params),
        refetchInterval: 5 * 60 * 1000 // refetch every 5 minutes
    });
}

export function useFetchDomainSubpages(params) {
    return useQuery({
        queryKey: [api.transSubpageListAll, params],
        queryFn: () => fetchWrapper.get(api.transSubpageListAll, params)
    });
}

export function useFetchDomainStats(params) {
    return useQuery({
        queryKey: [api.transDomainStats, params],
        queryFn: () => fetchWrapper.get(api.transDomainStats, params)
    });
}

export function useUpdateTranslation(queryParams) {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: body => {
            return fetchWrapper.put(api.translation, body);
        },
        onSuccess: () => {
            notification.success(t('successUpdateTranslation'));
            queryClient.invalidateQueries({
                queryKey: [api.transDomainAllTranslation, queryParams]
            });
        },
        onError: error => {
            console.error('useRemoveTranslation', error);
            notification.error(t('failedToUpdateTranslation'));
        }
    });
}

export function useRemoveTranslation(queryParams) {
    const queryClient = useQueryClient();
    const notification = useNotification();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: body => {
            return fetchWrapper.delete(api.translation, body);
        },
        onSuccess: () => {
            notification.success(t('successRemoveTranslation'));
            queryClient.invalidateQueries({
                queryKey: [api.transDomainAllTranslation, queryParams]
            });
        },
        onError: error => {
            console.error('useRemoveTranslation', error);
            notification.error(t('failedToRemoveTranslation'));
        }
    });
}

export function useCreatePdf() {
    return useMutation({
        mutationFn: ({
            html,
            title,
            language,
            margin = undefined,
            test = true,
            binaryOnly = false
        }) => {
            console.log('useCreatePdf', html);
            console.log(
                'useCreatePdf title',
                title,
                'language',
                language,
                'margin',
                margin,
                'test',
                test,
                'binaryOnly',
                binaryOnly
            );
            return fetchWrapper.post(api.createPdf, { html, title, language, margin, test });
        },
        onSuccess: (blob, props) => {
            console.log('useCreatePdf success', blob, props);

            if (props.binaryOnly) return blob;

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', props.title + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    });
}

export function useFetchOrganisationFiles(folder) {
    console.log('useFetchOrgFiles folder:', folder);

    return useQuery({
        queryKey: [api.orgFiles, folder],
        queryFn: () => fetchWrapper.get(api.orgFiles, { folder })
    });
}

/**
 * Fetch the contents of all files inside an orgs folder using the given entries from useFetchOrganisationFiles
 * @returns
 */
export function useFetchOrgFilesContents({ folder, entries }) {
    console.log('useFetchOrgFilesContents', folder, entries);

    const { i18n } = useTranslation();

    const [user] = useAtom(userAtom);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const _queries = useMemo(() => {
        if (!entries || !folder || !user.primaryOrganisation) {
            console.log(
                'useFetchOrgFilesContents | no entries or folder or user.primaryOrganisation',
                entries,
                folder,
                user.primaryOrganisation
            );
            return [];
        }

        return entries.map(entry => {
            return {
                queryKey: [
                    queryKeys.ORG_FILES_CONTENTS,
                    folder,
                    entry.name,
                    user.primaryOrganisation
                ],
                queryFn: () =>
                    fetchWrapper.get(
                        `${reportStorageUrl}/org_storage/${user.primaryOrganisation}/${folder}/${entry.name}`
                    )
            };
        });
    }, [entries, user.primaryOrganisation, folder]);

    const queries = useQueries({
        queries: _queries
    });

    const queriesLoading = queries.some(query => query.isLoading);

    const queriesError = queries.some(query => query.isError);

    const data = useMemo(() => {
        if (queriesError) {
            setIsError(true);
            setIsLoading(false);
            return [];
        }
        if (queriesLoading) {
            setIsLoading(true);
            return [];
        }

        setIsLoading(false);
        setIsError(false);

        const rows = [];

        if (!queries.length) return rows;

        for (const i in queries) {
            const file = queries[i].data;
            const data = entries[i];

            if (!data || !file) continue;

            const row = {
                dateChanged: formatDateToLocale(data.lastChanged, i18n.resolvedLanguage),
                name: data.name,
                file
            };

            rows.push(row);
        }

        return rows;
    }, [queriesLoading, queriesError, entries]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        data,
        isLoading,
        isError
    };
}

export function useUploadOrganisationFile() {
    return useMutation({
        mutationFn: ({ fileName, folder, data }) => {
            return fetchWrapper.post(api.orgFiles, { fileName, folder, data });
        },
        onSuccess: (res, props) => {
            console.log('useUploadOrganisationFile success', res, props);
        }
    });
}

export function useReloadOrganisationFiles() {
    const queryClient = useQueryClient();

    return folder => {
        queryClient.invalidateQueries({
            queryKey: [queryKeys.orgFiles, folder]
        });
    };
}

export function useFetchGeneratorFiles(cdnDirectory, entries) {
    const { i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();
    const _queries = useMemo(() => {
        if (!entries || !cdnDirectory) {
            console.log(
                'useFetchGeneratorFiles | no entries or cdnDirectory',
                entries,
                cdnDirectory
            );
            return [];
        }

        return entries.map(entry => {
            console.log('fetching with current timestamp', Date.now());
            return {
                queryKey: [queryKeys.GENERATOR_FILES, cdnDirectory, entry.name],
                queryFn: () =>
                    fetchWrapper.get(
                        `${reportStorageUrl}${cdnDirectory}/accessibilityStatements/${entry.name}?t=${Date.now()}`
                    )
            };
        });
    }, [cdnDirectory, entries]);

    const queries = useQueries({
        queries: _queries
    });

    const queriesLoading = queries.some(query => query.isLoading);

    const queriesError = queries.some(query => query.isError);

    const data = useMemo(() => {
        if (queriesError) {
            setIsError(true);
            setIsLoading(false);
            return [];
        }
        if (queriesLoading) {
            setIsLoading(true);
            return [];
        }

        setIsLoading(false);
        setIsError(false);

        const rows = [];

        if (!queries.length) return rows;

        for (const i in queries) {
            const file = queries[i].data;
            const data = entries[i];

            if (!data || !file) continue;

            const name = data.name.replace('.json', '');

            const [lang, wcagLvl, countryOrig] = name.split('_');
            let country = countryOrig;
            if (!countryOrig) {
                country = 'de';
            }
            const row = {
                language: t(lang),
                wcagLvl,
                country,
                dateChanged: formatDateToLocale(data.lastChanged, i18n.resolvedLanguage),
                name: data.name,
                file
            };

            rows.push(row);
        }

        return rows;
    }, [queriesLoading, queriesError, entries]); // eslint-disable-line react-hooks/exhaustive-deps

    const refetch = () => {
        queries.forEach(query => query.refetch());
    };

    return {
        data,
        isLoading,
        isError,
        refetch
    };
}

export function useFetchTranscriptionJobs() {
    return useQuery({
        queryKey: [api.transcription],
        queryFn: () => fetchWrapper.get(api.transcription),
        refetchInterval: 30 * 1000 // refetch every 30 seconds
    });
}

export function useRemoveTranscriptionJob() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: jobId => {
            return fetchWrapper.delete(api.transcription, {
                jobId
            });
        },
        onSuccess: (...args) => {
            console.log('useRemoveTranscriptionJob', args);
            queryClient.invalidateQueries({
                queryKey: [api.transcription]
            });
        }
    });
}

export function usePostTranscriptionJob() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: body => fetchWrapper.post(api.transcription, body),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [api.transcription]
            });
        }
    });
}

export function useFetchAuditEntryFiles(folder) {
    const [currentAuditEntryId] = useAtom(currentAuditEntryIdAtom);

    return useQuery({
        queryKey: [api.reportEntryFiles, currentAuditEntryId, folder],
        queryFn: () =>
            fetchWrapper.get(api.reportEntryFiles, { entryId: currentAuditEntryId, folder })
    });
}

export function useUploadAuditEntryFile() {
    return useMutation({
        mutationFn: ({ fileName, folder, data }) => {
            return fetchWrapper.post(api.reportEntryFiles, { fileName, folder, data });
        },
        onSuccess: (res, props) => {
            console.log('useUploadAuditEntryFile success', res, props);
        }
    });
}

export function usePostManualTest() {
    return useMutation({
        mutationFn: ({
            fileName,
            html,
            cdnDirectory,
            changedData,
            publish = false,
            existingFilePath,
            additionalMailText,
            sendMail = true,
            lang = 'en',
            auditEntryId
        }) => {
            console.log(
                'usePostManualTest',
                'filename',
                fileName,
                'cdnDirectory',
                cdnDirectory,
                'changedData',
                changedData,
                'publish',
                publish,
                'existingFilePath',
                existingFilePath,
                'sendMail',
                sendMail,
                'additionalMailText',
                additionalMailText,
                'lang',
                lang,
                'auditEntryId',
                auditEntryId
            );

            return fetchWrapper.post(api.manualTest, {
                fileName,
                html,
                cdnDirectory,
                changedData,
                publish,
                existingFilePath,
                sendMail: true,
                additionalMailText,
                lang,
                auditEntryId
            });
        },
        onSuccess: (res, props) => {
            console.log('usePostManualTest success', res, props);
        }
    });
}
